.add-tags-wrapper {
  .site-tag-plus {
    margin: 4px;
    background: #fff;
    border-style: dashed;
  }
  .edit-tag {
    margin: 4px;
    user-select: none;
  }
  .tag-input {
    margin: 4px;
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
  }
}
