.high-order-component {
  .search-banner {
    width: 100%;
    height: 200px;
  }
  .ant-btn-primary:not(.ant-input-search-button) {
    // background: green !important;
    // border-color: green !important;
  }

  .ant-btn-default:not(.ant-input-search-button) {
    &:hover {
      // color: green !important;
      // border-color: green !important;
    }
  }

  .full-screen-loader {
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 50;
    height: 60vh;
  }

  .ant-btn-primary {
    background: #7e7c61;
    border-color: #7e7c61;
    &:hover {
      background: #979685;
      border-color: #979685;
    }
    &:active {
      background: #979685;
      border-color: #979685;
    }
    &:focus {
      background: #979685;
      border-color: #979685;
    }
    &:focus-within {
      background: #979685;
      border-color: #979685;
    }
  }

  .ant-btn-default {
    border-width: 2px;
    color: #7e7c61;
    border-color: #707070;
    &:hover {
      color: #979685;
      border-color: #979685;
    }
    &:active {
      color: #979685;
      border-color: #979685;
    }
    &:focus {
      color: #979685;
      border-color: #979685;
    }
    &:focus-within {
      color: #979685;
      border-color: #979685;
    }
  }

  .spin-loader-font {
    font-size: 40px;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tag-checkable {
    border: 1px solid #eee;
    border-radius: 10px;
    margin: 4px;
    &:hover {
      border: 1px solid #aaa;
      color: #979685;
    }
  }

  .ant-tag-checkable-checked {
    background: #979685;
    border-color: #979685;
    &:hover {
      border: 1px solid #aaa;
      color: #fff;
    }
  }

  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #979685;
  }
}
