@import 'scss/media-query.scss';
.products-list-wrapper {
  .back-container {
    display: flex;
    justify-content: space-between;
    padding: 12px 32px;
    // padding-bottom: 0px;
    border: 1px solid #eee;
  }
  .business-description {
    padding: 32px;
    padding-bottom: 0;
    display: flex;
    justify-content: flex-start;
    img {
      height: 85px;
      margin-right: 12px;
      padding: 4px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .title {
      font-size: 32px;
      font-weight: bold;
    }
  }
}
