.signup-wrapper {
  min-height: 100vh;
  // background-repeat: no-repeat;
  background-size: cover;
  .signup-card {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-height: 100vh;

    .signup-card-container {
      // margin: 70px 0;
      max-width: 420px;
      min-width: 420px;
      min-height: 500px;
      max-height: 500px;
      background-color: #fff;
      border-radius: 8px;
      padding: 32px;
      overflow: scroll;
    }
  }

  .input-style {
    border: 0px solid #aaa !important;
    border-bottom: 1px solid #aaa !important;
    &:hover {
      border-right-width: 0px !important;
    }
  }

  // .ant-select-selector,
  // .ant-select-customize-input {
  //   border: 0px solid #aaa !important;
  //   &:hover {
  //     border-right-width: 0px !important;
  //   }
  // }

  h1 {
    font-size: 18px;
    font-weight: bold;
    color: #7e7c61;
  }

  .signup-card-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
    .top-container {
    }
  }

  .btn-flex {
    display: flex;
    justify-content: space-evenly;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .signinlabel {
    span {
      cursor: pointer;
    }
  }
}
