.button-component-wrapper {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 12px;
  border-radius: 4px;
  background-color: #fff;

  .icon {
  }
  .btn-text {
    text-align: center;
  }
}
