.super-admin-wrapper {
  margin-top: 120px;
  .super-admin-wrapper {
    display: flex;
    .admin-sidebar {
      min-height: 100vh;
      overflow-y: scroll;
      width: 300px;
      border-right: 1px solid #aaa;
    }

    .admin-right {
    }
  }
 
}
