.uploader-wrapper {
  img {
    height: 200px;
  }
  .btn-label {
    cursor: pointer;
    span {
      font-size: 30px;
    }
    font-size: 14px;

    width: 300px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #eee;
    text-align: center;
    border-radius: 16px;
  }
}

.picture-card {
  width: 300px; /* Change the width to your desired value */
}
