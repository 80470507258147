@import 'scss/media-query.scss';
.business-search-page {
  // margin-top: 65px;
  min-height: 100vh;
  width: 100%;
  .search-result-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 32px;
    align-items: center;
  }

  .search-result-wrapper {
    width: 100%;
    // display: flex;
    // justify-content: flex-start;
    .search-sidebar {
      // display: inline-block;
      // vertical-align: top;
      // width: 320px;
      border: 1px solid #ddd;
      //   position: absolute;
      min-height: 100vh;
      overflow: scroll;
      z-index: 6;
      padding: 32px;

      .categories {
        .category {
          cursor: pointer;
          &:hover {
            // color: green;
          }
        }
        .active {
          color: green;
          font-weight: bold;
        }

        .subcategories {
          margin-left: 14px;
        }
      }
    }

    .search-results {
      padding: 32px;
      padding-top: 0;
      display: flex;
      // justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      .business-info-card {
        margin-bottom: 24px;
        padding: 8px;
        cursor: pointer;
        img {
          width: 100%;
          padding: 12px;
          border: 1px solid #ccc;
          border-radius: 8px;
          min-height: 250px;
          min-width: 200px;
        }
        .title {
          margin-top: 12px;
          font-weight: bold;
        }

        .rating {
          .count {
            font-size: 12px;
            color: blue;
            svg {
              width: 8px;
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
}
