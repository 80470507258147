@import 'scss/media-query.scss';

.home-wrapper {
  margin-top: 55px;
  .banner {
    display: flex;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 32px;
    height: 95vh;

    button {
      background-color: #eee;
      border-color: #d9d9d9;
      color: black;
      font-size: 24px;
      padding: 50px;
      border-radius: 0 12px 12px 0 !important;
      height: 63px;
    }
    input {
      width: 50vw;
      max-width: 500px;
      font-size: 24px;
      padding: 12px;
      border-radius: 12px;
    }

    @include mobile {
      input {
        width: 65vw;
        font-size: 14px;
        padding: 8px;
        border-radius: 4px;
      }
      button {
        background-color: #eee;
        border-color: #d9d9d9;
        color: black;
        font-size: 14px;
        padding: 4px 16px;
        height: 39px;
        border-radius: 0 4px 4px 0 !important;
      }
    }
  }

  .suggestion-item {
    display: flex;
    align-items: center;
    @include mobile {
      flex-wrap: wrap;
      margin: 10px 0;
    }

    .item {
      margin: 4px;
      padding: 8px 12px;
      background-color: #ddd;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
    }
  }

  .ant-input-group {
    width: 100px;
  }

  .ant-input-group-addon {
    background-color: transparent;
  }

  .feature-card {
    padding: 32px;

    @include mobile {
      padding: 32px 8px;
      h1 {
        font-size: 18px;
        padding: 8px;
        padding-bottom: 0;
        font-weight: bold;
      }
    }
    .feature-card-wrapper {
      margin: 32px 0;
      .feature-card-container {
        background-color: #e5e5e2;
        border-radius: 16px;
        margin: 8px;
        // width: 100%;
        // justify-content: space-between;
        .heading {
          padding: 16px;
          .type-tag {
            background-color: #fff;
            width: auto;
            display: inline;
            padding: 4px 8px;
            border-radius: 8px;
          }
          .title-wrapper {
            margin-top: 18px;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            .title {
              font-weight: bold;
            }
            .rating-star {
              font-weight: bold;
            }

            .rating-count {
            }
          }
        }
        .image-wrapper {
          height: 300px;
          border-radius: 0 0 16px 16px;
        }
      }
    }
  }

  .how-it-works {
    padding: 32px;
    background-color: #e5e5e2;
    font-size: 20px;

    .how-it-works-wrapper {
      .how-it-works-container {
        @include mobile {
          margin-bottom: 32px;
        }
        max-width: 320px;
        .icon {
          img {
            height: 50px;
          }
        }
        .title {
          margin: 12px 0;
        }

        .description {
        }
      }
    }

    .ant-btn-default {
      border-radius: 8px;
      background-color: #e5e5e2;
      border: 2px solid black;
      color: black;
      margin-top: 32px;
      height: 50px;
      font-size: 20px;
    }
  }

  .app-description {
    display: flex;
    justify-content: space-evenly;
    padding: 40px 32px;

    .logo {
      span {
        margin-right: 12px;
        padding: 22px 8px;
        background-color: #e5e5e2;
        border-radius: 8px;
      }
      img {
        width: 50px;
      }
    }

    .image {
      img {
        width: 400px;
        @include mobile {
          width: 100%;
        }
      }
    }
    .description {
      padding-top: 12px;
      padding: 32px;
      @include mobile {
        padding: 2px;
        margin-top: 34px;
      }
      .title {
        font-size: 32px;
      }

      .app-info {
      }
    }

    .store-icons {
      margin-top: 24px;
      img {
        height: 40px;
      }
    }
  }
}
