.sign-in-email {
  .mandatory-message {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    span {
      color: navy;
    }
  }
}
