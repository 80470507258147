.rating-wrapper {
  .count {
    font-size: 12px;
    color: blue;
    svg {
      width: 8px;
      margin-right: 2px;
    }
  }
}
