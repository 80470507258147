.dashboard-wrapper {
  .page-title {
    padding: 8px 32px;
    border: 1px solid #aaa;
    h1 {
      margin: 0;
    }
  }

  .business-info {
    display: flex;
    justify-content: flex-start;
    img {
      height: 80px;
      margin-right: 32px;
    }

    .title {
      font-size: 32px;
      font-weight: bold;
    }
  }
}
