.email-verification-wrapper {
  height: 300px;
  margin-top: 100px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  .success,
  .error {
    font-size: 18px;
    padding: 32px;
    background-color: #fff;
    border-radius: 16px;
    text-align: center;
    border: 1px solid #eee;
  }
}
