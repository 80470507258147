.user-bueiness-wrapper {
  padding: 32px;

  .business-card {
    width: 100%;
    padding: 12px;
    // text-align: center;
    border-radius: 8px;
    .title {
      margin-top: 8px;
      color: #000;
      font-size: large;
      font-weight: bold;
    }
    img {
      border: 1px solid #eee;
    padding: 12px;
      border-radius: 8px;
      width: 100%;
    }

    // &:hover {
    //   background: #fafafa;
    // }
  }

  .business-list-contatiner {
    // display: flex;
    // flex-wrap: wrap;
  }
}
