.messages-admin-wrapper {
  padding: 32px;

  .messages-component-container {
    display: flex;
  }

  .messengers-list {
    border: 1px solid #eee;
    .messager-title {
      padding: 12px;
    }

    .business-id {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: bold;
      img {
        height: 40px;
        padding: 10px;
      }
    }
  }

  .messges-list-container {
    border: 1px solid #eee;
    height: 400px;
    background: #eee;
    overflow: scroll;
    margin-bottom: 30px;
    .message-header-title {
      background: #eee;
      padding: 16px;
    }

    .input-container {
      bottom: -26px;
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: stretch;
      align-items: stretch;
    }
  }

  .messages-list {
    .name-sender,
    .name-receiver {
      font-size: 10px;
      color: rgb(63, 62, 62);
      margin-bottom: -8px;
    }

    .name-sender {
      margin-right: 12px;
    }
    .name-receiver {
      margin-left: 12px;
    }

    .sender-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .receiver-wrapper {
      display: flex;
      justify-content: flex-start;
    }
    .receiver,
    .sender {
      padding: 8px;
      margin: 8px;
      background: #fff;
      border-radius: 4px;
      // max-width: 80%;
    }
    .sender {
      // display: flex;
      text-align: right;
    }

    .receiver {
      display: flex;
      justify-content: flex-start;
    }
  }
}
