.business-information-wrapper {
  padding: 32px;

  .ant-tag {
    border-radius: 8px;
    border: 0px solid transparent !important;
  }

  .logos {
    margin-bottom: 24px;
    img {
      padding: 4px;
      width: 30px;
    }
  }
}
