.admin-wrapper {
  .page-title {
    padding: 8px 32px;
    border: 1px solid #aaa;
    h1 {
      margin-bottom: 0;
    }
  }

  .form-wrapper {
    .input-style {
      background-color: #eee !important;
      border: 0px solid #aaa !important;
      border-bottom: 0px solid #aaa !important;
      &:hover {
        border-right-width: 0px !important;
      }
    }
    .remove-content {
      .ant-form-item-label > label::after {
        content: '';
      }
    }
  }
}
