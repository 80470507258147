.products-wrapper {
  padding: 32px;
  .product-card {
    padding: 12px;
    margin-bottom: 32px;
    margin-right: 16px;
    img {
      width: 100%;
      min-height: 150px;
      min-width: 150px;
      border-radius: 12px;
      padding: 12px;
      border: 1px solid #ccc;
    }
  }

  .show-hover {
    cursor: pointer;

    &:hover {
      background: #fafafa;
      border-radius: 8px;
    }
  }
}
