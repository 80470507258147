.chatbot-wrapper {
  button {
    font-family: sans-serif;
    font-size: 10px;
    background: green;
    text-align: left;
  }

  .rsc-header {
    background: green;
  }
  .rsc-header-title {
    color: white !important;
  }

  .rsc-os-option{
  }
}
