.update-admin-info-wrapper {
  padding-bottom: 12px;

  .business-info {
    display: flex;
    align-items: baseline;
    div {
      margin-right: 32px;
    }
  }
  .uploadfile {
    display: flex;
    justify-content: center;
  }

  .ext-link {
    position: absolute;
    margin-left: -20px;
    margin-top: 3px;
  }
}
