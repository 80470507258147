.update-info-wrapper {
  .form-wrapper,
  .business-info {
    padding: 32px;
  }
  .uploadfile {
    display: flex;
    justify-content: center;
  }

  .remove-content {
    .ant-form-item-label > label::after {
      content: '';
    }
  }
}
