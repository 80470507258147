.status-info-wrapper {
  .form-wrapper,
  .business-info {
    padding: 32px;
  }
  .uploadfile {
    display: flex;
    justify-content: center;
  }

  .form-wrapper {
    .form-header-title {
      display: flex;
      align-items: center;
      .tag-item {
        margin-left: 32px;
      }
      margin-bottom: 32px;
    }
    .input-style {
      background-color: #eee !important;
      border: 0px solid #aaa !important;
      border-bottom: 0px solid #aaa !important;
      &:hover {
        border-right-width: 0px !important;
      }
    }
    .remove-content {
      .ant-form-item-label > label::after {
        content: '';
      }
    }
  }
}
