$smallMobileWidth: 360;
$mobileWidth: 767;
$desktopWidth: 1441;
$smallDesktopWidth: 1440;
$tabletWidth: 768;
$tabletMaxWidth: 1024;

@mixin small {
  // this should always come after @include mobile()
  @media (max-width: $smallMobileWidth+px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobileWidth+px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktopWidth+px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tabletWidth+px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin smallDesktop {
  @media (max-width: 1200px) {
    @content;
  }
}
