.signin-home {
  padding: 32px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  min-height: 440px;
  button {
    width: 100%;
    margin: 4px 0;
  }

  .top-container {
  }

  .bottom-container {
    text-align: center;
  }
}
