.signup-wrapper {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  .signup-card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    .signup-card-container {
      max-width: 450px;
      min-width: 400px;
      min-height: 500px;
      background-color: #fff;
      border-radius: 4px;
      padding: 32px;
    }
  }

  .input-style {
    border: 0px solid #aaa !important;
    border-bottom: 1px solid #aaa !important;
    &:hover {
      border-right-width: 0px !important;
    }
  }

  .ant-select-selector,
  .ant-select-customize-input {
    border: 0px solid #aaa !important;
    &:hover {
      border-right-width: 0px !important;
    }
  }
}
