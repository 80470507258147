.sign-up-header {
  // display: flex;
  // justify-content: space-between;
  background-color: #fff;
  // padding: 8px 0px;
  // position: fixed;
  // width: 100vw;
  // .info {
  //   span {
  //     img {
  //       height: 25px;
  //       margin-right: 8px;
  //     }
  //   }
  // }
}
