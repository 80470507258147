@import 'scss/media-query.scss';
.footer-wrapper {
  z-index: 7;
  .site-data {
    color: #fff;
    background-color: #1e8131;
    padding: 32px;

    .menu-wrapper {
      .item {
        @include mobile {
          margin-bottom: 32px;
        }
      }

      li {
        list-style: none;
      }
    }

    .form-data {
    }
  }
}
.copyright-info {
  padding: 10px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
  div {
    @include mobile {
      margin-bottom: 12px;
    }
  }
  .logos {
    display: flex;
    align-items: center;
    img {
      height: 25px;
    }
    span {
      margin: 0 2px;
    }
  }
}
