@import 'scss/media-query.scss';
.header-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  @include mobile {
    height: 200px;
  }
  .info {
    span {
      img {
        height: 25px;
        margin-right: 8px;
      }
    }
  }
  .btns-top {
    @include mobile {
      display: none !important;
    }
  }
  .mobile-only {
    display: none !important;
    @include mobile {
      display: flex !important;
    }
  }
  .top-header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 0 16px; // padding: 12px 0;
    flex-wrap: wrap;
    .info {
      display: flex;
      align-items: center;
      font-size: 16px;
      // font-weight: bold;
      a {
        color: #000;
      }

      span {
        padding: 8px;
        padding-right: 0px;
        cursor: pointer;

        .avatar {
          margin-top: -10px;
          margin-left: 10px;
        }
      }
      .sign-out {
        color: #000;
      }

      .tab {
        margin: 0 4px;
      }
      .icon-round {
        svg {
          background-color: #eee;
          border-radius: 50%;
          padding: 2px;
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .bottom-header {
    @include mobile {
      display: none;
    }
    background-color: rgba(3, 124, 3, 0.829);
    padding: 1px 0;
    padding-top: 14px;
    font-size: 16px;
    color: #fff;
    // margin-left: -30px;
    a {
      color: #fff;
    }
    ul {
      display: flex;
    }

    li {
      list-style: none;
      span {
        margin: 0 16px;
      }
    }
  }
  .joinus {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .select-village-btn {
    border: 1px solid #aaa;
    padding: 4px 18px !important;
    border-radius: 4px;
    font-size: 14px;

    &:hover {
      background-color: #eee;
    }
  }

  .info-avatar {
    display: flex;
    align-items: center;
    span {
      padding: 0 !important;
      margin-left: 10px;
    }
  }
}

.village-modal-wrapper {
  padding: 36px;
  .button {
    text-transform: capitalize !important;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
  }
  // flex-direction: row;
  .village-btn {
    // display: inline-block;
    margin: 4px;
    padding: 4px;
  }
}
