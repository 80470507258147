.messanger-wrapper {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 350px;
  height: 500px;
  border: 1px solid #aaaaaa;
  z-index: 10;
  border-radius: 16px 16px 0 0;

  .input-container {
    position: absolute;
    width: 100%;
    bottom: -25px;
    display: flex;
  }

  .messages {
    padding-top: 12px;
    background: #eee;
    height: 430px;
    overflow: scroll;
    padding-bottom: 30px;

    .sender {
      padding: 8px;
      background: #fff;
      border-radius: 4px;
      max-width: 200px;
      margin: 8px;
    }
    .receiver,
    .sender {
      padding: 8px;
      margin: 8px;
      background: #fff;
      border-radius: 4px;
      // max-width: 80%;
    }
    .sender {
      // display: flex;
      text-align: right;
    }

    .receiver {
      text-align: left;
    }

    .name-sender,
    .name-receiver {
      font-size: 10px;
      color: rgb(63, 62, 62);
      margin-bottom: -8px;
    }

    .name-sender {
      margin-right: 12px;
    }
    .name-receiver {
      margin-left: 12px;
    }

    .sender-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .receiver-wrapper {
      display: flex;
      justify-content: flex-start;
    }
  }
}
